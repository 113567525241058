import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import Page from 'common/components/Page'
import RwdPaper from 'common/components/RwdPaper'
import { Button } from 'common/components/button/Button'
import PasswordField from 'common/components/textfield/PasswordField'
import useSocialProviderIcon from 'common/components/hooks/useSocialProviderIcon'
import { Subtitle, Body } from 'common/components/Typography'
import getSocialProviderName from 'common/utils/getSocialProviderName'

import ReCAPTCHA from 'common/components/ReCAPTCHA'

import styles from './styles.module.css'

const getHiddenEmail = email => {
  if (email) {
    const obj = email.split('@')
    if (obj && obj.length > 1) {
      const firstPart = obj[0].substring(0, 3)
      return `(${firstPart}${'*'.repeat(obj[0].length - 3)}@${obj[1]})`
    }
  }
}

const ExistingUser = ({
  errorMessage,
  providerId,
  inputs,
  onInputChange,
  onValidateSubmit,
  onCancel,
  intl,
  recaptchaRef,
  promptCaptcha,
}) => {
  const { icon, width, height } = useSocialProviderIcon(providerId)
  const { lastName, firstName, password, email } = inputs

  return (
    <RwdPaper>
      <div className={styles.panel}>
        <div className={classNames(styles.title, styles.withLargeBottomMargin)}>
          <Page.Title>
            {intl.formatMessage({ id: 'social-end.existing-user.title' })}
          </Page.Title>
        </div>
        <div className={styles.providerArea}>
          <img
            src={icon}
            width={width}
            height={height}
            alt="social-provider-logo"
          />
          <Subtitle>
            {firstName} {lastName}
          </Subtitle>
        </div>
        <Body mb={3}>
          <FormattedMessage
            id={`social-end.existing-user.message-with-email`}
            values={{ email: getHiddenEmail(email) }}
            tagName="p"
          >
            {message => {
              const formattedMessage = message[0]?.replace(
                '{email}',
                getHiddenEmail(email),
              )
              return (
                <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />
              )
            }}
          </FormattedMessage>
          <p>
            {intl.formatMessage(
              { id: 'social-end.existing-user.message-with-social-provider' },
              { socialProvider: getSocialProviderName(providerId) },
            )}
          </p>
        </Body>
        <PasswordField
          errorText={errorMessage}
          onChange={onInputChange('password')}
          value={password}
        />
        {promptCaptcha && <ReCAPTCHA ref={recaptchaRef} />}
        <div>
          <div className={classNames(styles.formButton, 'flex')}>
            <div className="mr2">
              <Button onClick={onValidateSubmit}>
                {intl.formatMessage({ id: 'common.button.verify' })}
              </Button>
            </div>
            <Button onClick={onCancel} variant="outlined">
              {intl.formatMessage({
                id: 'social-end.existing-user.button.not-me',
              })}
            </Button>
          </div>
        </div>
      </div>
    </RwdPaper>
  )
}

export default injectIntl(ExistingUser)
