import React from 'react'
import { useIntl } from 'react-intl'
import DetailView from 'protected/common/DetailView'
import { DialogTitle } from '../../../common/components/Typography'
import { List, UserButton } from '@admin-ui-common/base-user'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import MessageIcon from '@mui/icons-material/TextsmsOutlined'
import { Box, Divider } from '@mui/material'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  iconWrapper: {
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: '40px',
  },
  bold: {
    fontWeight: 'bold',
  },
}))

const AvailableTwoStepMethodsView = ({
  setupTextMessageOrEmail,
  setupAuthenticatorApp,
  backLink,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <DetailView
      title={intl.formatMessage({
        id: 'profile.detail.two-step-verification.title',
      })}
      description={intl.formatMessage({
        id: 'profile.detail.two-step-verification.description',
      })}
      backLink={backLink}
    >
      <Box mb={2}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'profile.label.select-a-verification-method',
          })}
        </DialogTitle>
      </Box>

      <Box mx="-16px">
        <List>
          <List.ListItem>
            <List.ListItemIcon className={classes.iconWrapper}>
              <PhoneAndroidIcon className={classes.icon} />
            </List.ListItemIcon>
            <List.ListItemText
              primary={
                <Typography className={classes.bold} variant="body1">
                  {intl.formatMessage({
                    id: 'profile.label.authenticator-app',
                  })}
                </Typography>
              }
              secondary={
                <div>
                  <span className={classes.bold}>
                    {intl.formatMessage({
                      id: 'profile.detail.authenticator-app.recommended',
                    })}
                  </span>
                  {intl.formatMessage({
                    id: 'profile.detail.authenticator-app.use-auth-app',
                  })}
                </div>
              }
            />
          </List.ListItem>
          <Box mt={1} mb={4} ml={9}>
            <UserButton size="small" onClick={setupAuthenticatorApp}>
              {intl.formatMessage({
                id: 'profile.detail.authenticator-app.button.setup-auth-app',
              })}
            </UserButton>
          </Box>
          <Divider light variant="middle" />
          <Box mt={1} />
          <List.ListItem>
            <List.ListItemIcon className={classes.iconWrapper}>
              <MessageIcon className={classes.icon} />
            </List.ListItemIcon>
            <List.ListItemText
              primary={
                <Typography className={classes.bold} variant="body1">
                  {intl.formatMessage({
                    id: 'profile.detail.authenticator-app.text-or-email',
                  })}
                </Typography>
              }
              secondary={intl.formatMessage({
                id:
                  'profile.detail.authenticator-app.get-text-or-email-verification',
              })}
            />
          </List.ListItem>
          <Box mt={1} ml={9}>
            <UserButton
              size="small"
              variant="outlined"
              onClick={setupTextMessageOrEmail}
            >
              {intl.formatMessage({
                id: 'profile.detail.authenticator-app.setup-text-or-email',
              })}
            </UserButton>
          </Box>
        </List>
      </Box>
    </DetailView>
  )
}

export default AvailableTwoStepMethodsView
