import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import withStyles from '@mui/styles/withStyles'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'

import DetailView from 'protected/common/DetailView'
import PasswordField from 'common/components/textfield/PasswordField'
import { isCredentialSet } from 'protected/profile/selectors'
import { loadUser } from 'common/actions/user'
import FeatureFlagsService from 'common/services/FeatureFlagsService'
import { formatErrorMessage } from '../../../common/utils/processBackendErrors'

import { updatePassword } from 'common/processes/userManagement'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { TextPrimaryFillButton } from '../../../common/components/button/Button'
import { analyticsProvider } from '../../../App'

import { InlineNotification } from '@admin-ui-common/base-user'

import { withWidth } from 'common/components/hoc/withWidth'

const styles = () => ({
  password: {
    marginTop: 16,
  },
})

const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.passwordDetailView.${feature}`)

class PasswordDetailView extends React.PureComponent {
  static propTypes = {
    addNotification: PropTypes.func,
    isCredentialSet: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'password_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    errors: {
      newPassword: '',
      currentPassword: '',
      confirmPassword: '',
    },
    inProgress: false,
    errorResponseBody: null,
  }

  sendUpdatePasswordFailedAnalytics = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-password`,
      value: 1,
    })
  }

  onPasswordUpdate = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-password`,
      value: 0,
    })

    const { currentPassword, newPassword, newPasswordConfirm } = this.state
    const { intl, history, addNotification, isCredentialSet } = this.props
    const showConfirmPassword = hasFeatureFlag('showConfirmPassword')

    this.setState({ errors: {}, errorResponseBody: null })

    if (showConfirmPassword && newPassword !== newPasswordConfirm) {
      this.sendUpdatePasswordFailedAnalytics()

      this.setState({
        errors: {
          confirmPassword: intl.formatMessage({
            id: 'error.not-match-new-password',
          }),
        },
      })
      return
    }

    if (isCredentialSet && !currentPassword) {
      this.sendUpdatePasswordFailedAnalytics()
      this.setState({
        errors: {
          currentPassword: intl.formatMessage({
            id: 'error.credential',
          }),
        },
      })
      return
    }

    if (!newPassword) {
      this.sendUpdatePasswordFailedAnalytics()
      this.setState({
        errors: {
          newPassword: intl.formatMessage({
            id: 'error.new-password',
          }),
        },
      })
      return
    }

    if (showConfirmPassword && !newPasswordConfirm) {
      this.sendUpdatePasswordFailedAnalytics()
      this.setState({
        errors: {
          confirmPassword: intl.formatMessage({
            id: 'error.confirm-password',
          }),
        },
      })
      return
    }

    this.setState({ inProgress: true })

    const requestParams = {
      oldPassword: currentPassword,
      newPassword,
    }
    if (!isCredentialSet) delete requestParams.oldPassword

    updatePassword(requestParams)
      .then(() => {
        addNotification({
          message: intl.formatMessage({ id: 'notification.password-changed' }),
          variant: 'success',
        })
        this.props.loadUser()

        this.setState({ inProgress: false }, () => {
          history.push('/')
        })
      })
      .catch(err => {
        this.sendUpdatePasswordFailedAnalytics()

        this.setState({
          inProgress: false,
          errorResponseBody: err.body,
        })
      })
  }

  handlePasswordChange = type => e => this.setState({ [type]: e.target.value })

  render() {
    const { intl, classes, isCredentialSet, backLink } = this.props
    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
      errors,
      inProgress,
      errorResponseBody,
    } = this.state
    const showConfirmPassword = hasFeatureFlag('showConfirmPassword')

    const classesWrapper = classNames('flex flex-column')

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.password.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.password.description',
        })}
        backLink={backLink}
      >
        <Collapse in={Object.values(errors).some(str => str)}>
          <Box mb={3}>
            <InlineNotification
              title={
                errors.confirmPassword ||
                errors.currentPassword ||
                errors.newPassword
              }
              variant="error"
              handleClose={() => {
                this.setState({ errors: {} })
              }}
            />
          </Box>
        </Collapse>
        <Collapse in={!!errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                this.setState({
                  errorResponseBody: null,
                })
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
              context="change-password"
            />
          </Box>
        </Collapse>

        <div className={classesWrapper}>
          {isCredentialSet && (
            <PasswordField
              label={intl.formatMessage({
                id: 'common.label.current-password',
              })}
              onChange={this.handlePasswordChange('currentPassword')}
              value={currentPassword}
              fullWidth
            />
          )}
          <PasswordField
            className={classes.password}
            label={intl.formatMessage({ id: 'common.label.new-password' })}
            onChange={this.handlePasswordChange('newPassword')}
            value={newPassword}
            fullWidth
          />
          {showConfirmPassword && (
            <PasswordField
              className={classes.password}
              errorText={errors.confirmPassword}
              label={intl.formatMessage({
                id: 'common.label.confirm-new-password',
              })}
              onChange={this.handlePasswordChange('newPasswordConfirm')}
              value={newPasswordConfirm}
              fullWidth
            />
          )}
        </div>

        <div className="flex mt3">
          <TextPrimaryFillButton
            size="large"
            onClick={this.onPasswordUpdate}
            isLoading={inProgress}
          >
            {isCredentialSet
              ? intl.formatMessage({ id: 'common.button.change-password' })
              : intl.formatMessage({ id: 'common.button.set-password' })}
          </TextPrimaryFillButton>
        </div>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  isCredentialSet: isCredentialSet(state),
})

const mapDispatchToProps = {
  loadUser,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withWidth(),
  withRouter,
  withStyles(styles),
)(PasswordDetailView)
