import { useState } from 'react'
import {
  TopHeader,
  SidebarNavigationLayout as SidebarNavigationLayoutBase,
} from '@admin-ui-common/base-user'
import { useMediaQuery } from '@mui/material'
import SideBar from 'protected/common/SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { getDisplayName } from 'common/components/nameField/NameFields'
import { FormattedMessage } from 'react-intl'
import { endSession as endSessionBase } from 'common/actions/session'
import { useTheme } from '@mui/material/styles'
import useLogoProvider from 'common/components/hooks/useLogoProvider'

const sidebarStates = {
  SHOWN: 'SHOWN',
  HIDDEN: 'HIDDEN',
}

export default function SidebarNavigationLayout({
  title,
  children,
  onBackArrowClick = null,
}) {
  const theme = useTheme()
  const { logoImage } = useLogoProvider()
  const shouldIntegrateNavbar = useMediaQuery(
    `@media (max-width: ${theme.breakpoints.values.lg}px)`,
  )
  const [sidebarState, setSidebarState] = useState(sidebarStates.HIDDEN)
  const dispatch = useDispatch()
  const displayName = useSelector(getDisplayName)
  const endSession = () => {
    endSessionBase()(dispatch)
  }

  const sidebarToggle = () => {
    setSidebarState(
      sidebarState === sidebarStates.SHOWN
        ? sidebarStates.HIDDEN
        : sidebarStates.SHOWN,
    )
  }

  return (
    <SidebarNavigationLayoutBase
      title={title}
      navbarSlot={
        <TopHeader
          position="sticky"
          showSidebarIcon={shouldIntegrateNavbar}
          user={{
            displayName,
          }}
          logoUrl={logoImage}
          sidebarToggle={sidebarToggle}
          profileMenuOptions={[
            {
              label: (
                <FormattedMessage
                  id="common.button.sign-out"
                  defaultMessage="Sign Out"
                />
              ),
              action: endSession,
            },
          ]}
        />
      }
      sidebarSlot={
        <SideBar
          open={sidebarState === sidebarStates.SHOWN}
          sidebarToggle={sidebarToggle}
        />
      }
      onBackArrowClick={onBackArrowClick}
    >
      {children}
    </SidebarNavigationLayoutBase>
  )
}
