import process from 'common/utils/process'
import { get } from 'lodash'
import i from 'common/utils/i'
import conf from 'conf'
import * as constants from 'common/constants/twoStepVerification'
import {
  AUTHN_IDENTIFIER_PROMPT,
  OTP_PROMPT,
  GET_TRUSTED_DEVICES_ERROR,
  GET_TRUSTED_DEVICES_RESPONSE,
} from 'common/constants/twoStepVerification'
import isPrivacyAndConsentFlow from 'common/utils/isPrivacyAndConsentFlow'
import { isStepUpFlow } from 'common/utils/routeUtils'

const urlRoot = `${conf.apiRoot}/process`

const registerAuthnIdentifierProcessId =
  'userManagement.RegisterAuthnIdentifierAsMFA.v1.0'

const registerAuthenticatorAppProcessId =
  'userManagement.RegisterAuthenticatorAppAsMFA.v1.0'

const registerAuthnIdAsMFABegin = {
  type: constants.POST_REGISTER_MFA_EMAIL_PHONE_REQUEST,
}

const registerAuthnIdAsMFASuccess = payload => ({
  type: constants.POST_REGISTER_MFA_EMAIL_PHONE_SUCCESS,
  payload,
})

const registerAuthnIdAsMFAFailure = error => ({
  type: constants.POST_REGISTER_MFA_EMAIL_PHONE_FAILURE,
  error,
})

const registerAuthAppAsMFABegin = {
  type: constants.POST_REGISTER_MFA_APP_REQUEST,
}

const registerAuthAppAsMFASuccess = payload => ({
  type: constants.POST_REGISTER_MFA_APP_SUCCESS,
  payload,
})

const registerAuthAppAsMFAFailure = error => ({
  type: constants.POST_REGISTER_MFA_APP_FAILURE,
  error,
})

const getTrustedDevicesResponse = response => ({
  type: GET_TRUSTED_DEVICES_RESPONSE,
  response,
})

const getTrustedDevicesError = error => ({
  type: GET_TRUSTED_DEVICES_ERROR,
  error,
})

export const RegisterAuthnIdAsMFA = () => async dispatch => {
  dispatch(registerAuthnIdAsMFABegin)
  return await process
    .start(registerAuthnIdentifierProcessId)
    .then(response => {
      const payload = {
        stepName: response.body.stepName,
        processId: response.body.processId,
      }
      dispatch(registerAuthnIdAsMFASuccess(payload))
      return response
    })
    .catch(error => {
      dispatch(registerAuthnIdAsMFAFailure(error))
      return Promise.reject(error)
    })
}

export const RegisterAuthenticatorAppAsMFA = () => async dispatch => {
  dispatch(registerAuthAppAsMFABegin)
  return await process
    .start(registerAuthenticatorAppProcessId)
    .then(response => {
      const payload = {
        stepName: response.body.stepName,
        processId: response.body.processId,
      }
      dispatch(registerAuthAppAsMFASuccess(payload))
      return response
    })
    .catch(error => {
      dispatch(registerAuthAppAsMFAFailure(error))
      return Promise.reject(error)
    })
}

const initialStepRequest = {
  type: constants.POST_REGISTER_MFA_STEP_REQUEST,
}

const stepRequestSuccess = payload => ({
  type: constants.POST_REGISTER_MFA_STEP_SUCCESS,
  payload,
})

const stepRequestFailure = error => ({
  type: constants.POST_REGISTER_MFA_STEP_FAILURE,
  error,
})

const UpdateSelectedIdentity = payload => ({
  type: constants.UPDATE_SELECTED_IDENTITY,
  payload,
})

const clearEmailOrPhone = () => ({
  type: constants.CLEAR_EMAIL_PHONE,
})

const getPayload = response => {
  const stepName = get(response, 'body.stepName')

  switch (stepName) {
    case AUTHN_IDENTIFIER_PROMPT:
      return {
        stepName: stepName,
        authnIdentifiers: get(response, 'body.output.authnIdentifiers'),
        processId: get(response, 'body.processId'),
      }
    case OTP_PROMPT:
      return {
        stepName: stepName,
        pkat: get(response, 'body.output.pkat'),
        processId: get(response, 'body.processId'),
      }
    default:
      return get(response, 'body')
  }
}

export const registerMFAVerificationStep = payload => async dispatch => {
  dispatch(initialStepRequest)
  return await process
    .step(payload, true)
    .then(response => {
      const lastStep = get(response, 'body.lastStep')
      if (lastStep) return response

      const payload = getPayload(response)
      dispatch(stepRequestSuccess(payload))

      return response
    })
    .catch(error => {
      dispatch(stepRequestFailure(error))
      return Promise.reject(error)
    })
}

export const updateSelectedIdentity = selectedIdentity => dispatch => {
  dispatch(UpdateSelectedIdentity(selectedIdentity))
}

export const clearEmailPhone = () => dispatch => {
  dispatch(clearEmailOrPhone())
}

// UnRegister MFA Email and mobile
export const unregisterMFAIntialRequest = async (identity, type) => {
  const unregisterType =
    type === 'emailmobile'
      ? `userManagement.UnregisterAuthnIdentifierAsMFA.v1.0?authnIdentifier=${identity}`
      : 'userManagement.UnregisterAuthenticatorAppAsMFA.v1.0'
  const unregisterUrl = `${urlRoot}/start/${unregisterType}`
  const response = await i.post(unregisterUrl)
  return response
}

export const unregisterMFAStep = async payload => {
  return await process
    .step(payload)
    .then(response => response)
    .catch(error => Promise.reject(error))
}

export const getTrustedDevices = () => dispatch => {
  const trustedDevicesUrl = `${conf.apiRoot}/user/runtimes`

  return i
    .get(trustedDevicesUrl, {
      ignoreErrorRedirect: true,
      ...(isPrivacyAndConsentFlow || isStepUpFlow
        ? { headers: { 'x-idp-authentication': true } }
        : {}),
    })
    .then(res => {
      dispatch(getTrustedDevicesResponse(res))
    })
    .catch(err => {
      dispatch(getTrustedDevicesError(err))
    })
}

export const revokeAllTrustedDevices = () => () => {
  const trustedDevicesUrl = `${conf.apiRoot}/process/start/userManagement.RevokeTrustedDevices.v1.0`
  const data = {
    parameters: {
      runtimeIds: [],
    },
  }
  return i.post(trustedDevicesUrl, { data, ignoreErrorRedirect: true })
}

export const generateBackupCodes = () => () => {
  const bucUrl = `${conf.apiRoot}/user/buc`
  return i.post(bucUrl, { ignoreErrorRedirect: true })
}

export const deleteBackupCodes = () => () => {
  const bucUrl = `${conf.apiRoot}/user/buc`
  return i.delete(bucUrl, { ignoreErrorRedirect: true })
}
