import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import withStyles from '@mui/styles/withStyles'
import { InlineNotification } from '@admin-ui-common/base-user'

import DetailView from 'protected/common/DetailView'
import { isPinSet } from 'protected/profile/selectors'
import { loadUser, updateAttribute } from 'common/actions/user'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Button } from '../../../common/components/button/Button'
import { analyticsProvider } from '../../../App'

import TextField from 'common/components/textfield/TextField'
import { addNotification } from 'common/actions/notification'
import {
  formatErrorTitle,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'

import { withWidth } from 'common/components/hoc/withWidth'

const styles = () => ({
  pin: {
    marginTop: 16,
  },
})

class PinDetailView extends React.PureComponent {
  static propTypes = {
    addNotification: PropTypes.func,
    isPinSet: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'pin_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    newPin: '',
    errors: {
      newPin: '',
    },
    inProgress: false,
  }

  sendUpdatePinFailedAnalytics = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-pin`,
      value: 1,
    })
  }

  onPinUpdate = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-pin`,
      value: 0,
    })

    const { newPin } = this.state
    const { intl, addNotification, updateAttribute } = this.props

    this.setState({ errors: {} })

    if (!newPin) {
      this.sendUpdatePinFailedAnalytics()
      this.setState({
        errors: {
          newPin: formatErrorTitle({
            intl,
            id: 'error.pin-not-empty',
          }),
        },
      })
      return
    }

    const requestParams = {
      pin: newPin,
    }

    this.setState({ inProgress: true })

    updateAttribute(requestParams)
      .then(() => {
        this.setState({ inProgress: false }, () => {
          addNotification({
            message: intl.formatMessage({ id: 'notification.pin-changed' }),
            variant: 'success',
          })
        })
      })
      .catch(err => {
        this.sendUpdatePinFailedAnalytics()
        this.setState({
          inProgress: false,
          errorResponseBody: err.body,
        })
      })
  }

  handlePinChange = type => e => this.setState({ [type]: e.target.value })

  render() {
    const { intl, classes, isPinSet, backLink } = this.props
    const { newPin, errors, inProgress, errorResponseBody } = this.state

    const classesWrapper = classNames('flex flex-column')

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.pin.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.pin.description',
        })}
        backLink={backLink}
      >
        <Collapse in={errors.newPin}>
          <Box mb={3}>
            <InlineNotification
              title={errors.newPin}
              variant="error"
              handleClose={() => {
                this.setState({ errors: {} })
              }}
            />
          </Box>
        </Collapse>
        <Collapse in={!!errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least.context.change-pin',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                this.setState({
                  errorResponseBody: null,
                })
              }}
              context="change-pin"
            />
          </Box>
        </Collapse>

        <div className={classesWrapper}>
          <TextField
            className={classes.pin}
            label={intl.formatMessage({ id: 'common.label.new-pin' })}
            onChange={this.handlePinChange('newPin')}
            value={newPin}
            fullWidth
            inputType="password"
            numeric
          />
        </div>

        <div className="flex mt3">
          <Button
            size="large"
            onClick={this.onPinUpdate}
            isLoading={inProgress}
          >
            {isPinSet
              ? intl.formatMessage({ id: 'common.button.change-pin' })
              : intl.formatMessage({ id: 'common.button.set-pin' })}
          </Button>
        </div>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  isPinSet: isPinSet(state),
})

const mapDispatchToProps = {
  loadUser,
  addNotification,
  updateAttribute,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withWidth(),
  withRouter,
  withStyles(styles),
)(PinDetailView)
