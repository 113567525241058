import { AnalyticsProviderFactory, useLocale } from '@admin-ui-common/utils'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Hidden from '@mui/material/Hidden'
import AppWrapper from 'AppWrapper'
import useCustomTheme from 'common/components/hooks/useCustomTheme'
import useLogoProvider from 'common/components/hooks/useLogoProvider'
import Notification from 'common/components/Notification'
import FeatureFlagsService, {
  hasUIFeatureFlag,
} from 'common/services/FeatureFlagsService'
import ServiceInitializer from 'common/services/ServiceInitializer'
import { isUnsupportedBrowser } from 'common/utils/getBrowserDetails'
import getCurrentSubRouteConfiguration from 'common/utils/getCurrentSubRouteConfiguration'
import { isDictionaryDebugOn } from 'common/utils/isDictionaryDebugOn'
import conf from 'conf'
import DeviceActivate from 'device-activate/device-activate'
import Lander from 'lander/Lander'
import Redirector from 'lander/Redirector'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import AccountsPage from 'protected/accounts'
import ProfilePage from 'protected/profile'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import { updateContext } from './common/actions/configuration'
import { getDictionary } from './common/actions/dictionary'
import { getTrustedDevices } from './common/actions/twoStepVerification'
import logo from './common/assets/logos/logo-attmx.svg'
import CustomRouter from './common/components/CustomRouter'
import { MATOMO_PROVIDER } from './common/constants/analytics'
import { getAvailableLocales, messagesMap } from './common/constants/locale'
import getLogoImage from './common/utils/getLogoImage'
import Theme from './common/utils/theme'
import NotLoaded from './common/wizards/common/NotLoaded'
import UserConfirmPage from './lander/invitation/UserConfirmPage'
import ConfirmUserByToken from './lander/signup/ConfirmUserByToken'
import UnsupportedBrowser from './lander/UnsupportedBrowser'
import SecurityAndSignInPage from './protected/security-and-sign-in'
import SocialEnd from './social/SocialEnd'
import store from './store'
import { GlobalStyle } from './styles'
import getFavicons from 'common/utils/getFavicons'
import StepupProcess from './lander/stepup/StepupProcess'
import PrivacyAndConsentPage from 'lander/privacy-and-consent/PrivacyAndConsentPage'
import { PRIVACY_AND_CONSENT_ROUTE } from 'common/utils/isPrivacyAndConsentFlow'
import { IMPERSONATING_USER_CONSENT_ROUTE } from 'common/constants/impersonation'
import ConsentResultPage from 'lander/impersonation-consent/ConsentResultPage'
import { STEP_UP_ROUTE } from 'common/utils/routeUtils'

export const analyticsProvider = new AnalyticsProviderFactory(
  conf.analyticsProvider || MATOMO_PROVIDER,
  conf,
)

export const LogoContext = React.createContext(null)

const App = () => {
  const state = useSelector(state => state)

  const appTitle = state.configuration.config.appTitle
  const detectLocale = state.configuration.config.detectLocale
  const defaultLocale = state.configuration.config.defaultLocale
  const hasSession = state.session.hasSession
  const isSessionCheckLoading = state.session.isSessionCheckLoading
  const user = state.user
  const unAuthUserLocale = state.locale
  const enableOnlyWizardRoutes =
    state.configuration.config.enableOnlyWizardRoutes
  const tenantID = state.settings.tenantID
  const dictionaryMessages = state.dictionary.messages
  const settingsError = state.settings.error

  const favicons = useMemo(getFavicons, [hasUIFeatureFlag('favicons')])
  const { logoImage } = useLogoProvider()
  const [serviceInitialized, setServiceInitialized] = useState(false)
  const [dictionaryInitialized, setDictionaryInitialized] = useState(false)

  const [logoUrl, setLogoUrl] = useState(logoImage)

  const locale = useLocale(
    user,
    unAuthUserLocale,
    hasSession,
    FeatureFlagsService.get('system.defaultLocale'),
    detectLocale,
    defaultLocale,
    getAvailableLocales(),
  )

  const { dispatch } = store

  const loadIntlPolyfills = useCallback(
    async function loadIntlPolyfills() {
      const formatjsLocales = {
        pluralrules: {
          es: () => import('@formatjs/intl-pluralrules/dist/locale-data/es'),
          en: () => import('@formatjs/intl-pluralrules/dist/locale-data/en'),
          polyfill: () => import('@formatjs/intl-pluralrules/polyfill'),
        },
        relativetimeformat: {
          es: () =>
            import('@formatjs/intl-relativetimeformat/dist/locale-data/es'),
          en: () =>
            import('@formatjs/intl-relativetimeformat/dist/locale-data/en'),
          polyfill: () => import('@formatjs/intl-relativetimeformat/polyfill'),
        },
      }

      if (!Intl.PluralRules) {
        await formatjsLocales.pluralrules.polyfill()
        get(formatjsLocales.pluralrules, locale, defaultLocale)()
      }

      if (!Intl.RelativeTimeFormat) {
        await formatjsLocales.relativetimeformat.polyfill()
        get(formatjsLocales.relativetimeformat, locale, defaultLocale)()
      }

      moment.locale(locale)
    },
    [defaultLocale, locale],
  )

  useEffect(() => {
    if (locale && defaultLocale) {
      loadIntlPolyfills()
    }
  }, [locale, defaultLocale, loadIntlPolyfills])

  const initializeRouteContext = useCallback(() => {
    const { currentSubRoute, config } = getCurrentSubRouteConfiguration()
    updateContext(currentSubRoute, config)(dispatch)
  }, [dispatch])

  useEffect(() => {
    //TODO Fix matomo analytics and re-enable
    // const externalProviders = conf.tagEvents ? [analyticsProvider.init()] : []
    const externalProviders = []

    ServiceInitializer.init(externalProviders).finally(() => {
      initializeRouteContext()
      setServiceInitialized(true)
    })
  }, [initializeRouteContext])

  useEffect(() => {
    getTrustedDevices()(dispatch)
  }, [dispatch])

  useEffect(() => {
    if (tenantID && locale) {
      getDictionary(
        tenantID,
        locale,
      )(dispatch).finally(() => setDictionaryInitialized(true))
    }
  }, [tenantID, locale, dispatch])

  const { theme } = useCustomTheme()

  if (!serviceInitialized) {
    return null
  }

  const apiGatewayURL = hasUIFeatureFlag('apiGatewayURL')

  if (
    apiGatewayURL &&
    tenantID &&
    locale &&
    !dictionaryInitialized &&
    isEmpty(dictionaryMessages)
  ) {
    return null
  }

  if (!hasSession && isSessionCheckLoading) {
    return null
  }

  const showNavBar = !isUnsupportedBrowser() && !enableOnlyWizardRoutes
  const showOperatorLogo = hasUIFeatureFlag('showOperatorLogo')

  const dictionary = !isEmpty(dictionaryMessages)
    ? { ...messagesMap[locale], ...dictionaryMessages }
    : messagesMap[locale]

  const messages = isDictionaryDebugOn() ? {} : dictionary

  if (isUnsupportedBrowser()) {
    return (
      <React.Fragment>
        <AppWrapper>
          <UnsupportedBrowser />
        </AppWrapper>
      </React.Fragment>
    )
  }

  const hasSettingsError = !isEmpty(settingsError)

  return (
    <Theme theme={theme}>
      <GlobalStyle theme={theme} />
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
        textComponent="span"
      >
        <React.Fragment>
          <Helmet link={favicons}>
            <title>{appTitle}</title>
          </Helmet>
          <CssBaseline />
          <LogoContext.Provider
            value={{ onLogoUrl: setLogoUrl, logoUrl: logoUrl }}
          >
            <CustomRouter>
              {hasSettingsError && (
                <>
                  <Redirect to="/something-went-wrong" />
                  <Route path="/something-went-wrong" component={NotLoaded} />
                </>
              )}
              {!showNavBar && (
                <Hidden smDown>
                  <Box
                    display="flex"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      top={24}
                      left={36}
                      src={getLogoImage(logoUrl)}
                      alt="logo"
                      height={48}
                    />
                    {showOperatorLogo && (
                      <Box
                        component="img"
                        top={24}
                        left={36}
                        src={logo}
                        alt="logo"
                        height={40}
                      />
                    )}
                  </Box>
                </Hidden>
              )}
              {!hasSettingsError && (
                <Switch>
                  <AuthenticatedRoute
                    path="/activate"
                    redirectBack
                    exact
                    component={DeviceActivate}
                  />
                  {!enableOnlyWizardRoutes && [
                    <Route
                      exact
                      path={STEP_UP_ROUTE}
                      component={StepupProcess}
                      key="stepup"
                    />,
                    <AuthenticatedRoute
                      path="/profile"
                      key="profile"
                      component={ProfilePage}
                    />,
                    <AuthenticatedRoute
                      path="/accounts"
                      component={AccountsPage}
                      key="accounts"
                    />,
                    <AuthenticatedRoute
                      path="/security-and-sign-in"
                      component={SecurityAndSignInPage}
                      key="security-and-sign-in"
                    />,
                    <AuthenticatedRoute
                      exact
                      path="/household_invitation"
                      component={UserConfirmPage}
                      key="household_invitation"
                    />,
                    <AuthenticatedRoute
                      exact
                      path="/user_confirm"
                      component={ConfirmUserByToken}
                      key="user_confirm"
                    />,
                    <AuthenticatedRoute
                      path={PRIVACY_AND_CONSENT_ROUTE}
                      exact
                      component={PrivacyAndConsentPage}
                    />,
                    hasSession && (
                      <Route
                        path={IMPERSONATING_USER_CONSENT_ROUTE}
                        exact
                        component={ConsentResultPage}
                      />
                    ),
                    hasSession && (
                      <Route
                        exact
                        path="/social_end"
                        render={() => <SocialEnd locale={locale} />}
                        key="social_end"
                      />
                    ),
                    hasSession && (
                      <Route
                        render={({ location }) => (
                          <Redirect
                            to={{
                              pathname: '/profile',
                              search: location.search,
                            }}
                          />
                        )}
                        key="default"
                      />
                    ),
                  ]}
                  <Route path="/login" exact component={Redirector} />
                </Switch>
              )}
              {!hasSession && !hasSettingsError && (
                <AppWrapper
                  hasSession={hasSession}
                  enableOnlyWizardRoutes={enableOnlyWizardRoutes}
                >
                  <Lander enableOnlyWizardRoutes={enableOnlyWizardRoutes} />
                </AppWrapper>
              )}
              <Notification />
            </CustomRouter>
          </LogoContext.Provider>
        </React.Fragment>
      </IntlProvider>
    </Theme>
  )
}

export default App
