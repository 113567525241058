import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { Button } from 'common/components/button/Button'

import DetailView from 'protected/common/DetailView'
import { useFido2Register } from 'common/components/hooks/useFido2Registration'

const Fido2View = ({ backLink }) => {
  const intl = useIntl()
  const [inProgress, setInProgress] = useState(false)

  const registerFido = useFido2Register()

  const handleClick = useCallback(() => {
    setInProgress(true)
    registerFido(() => setInProgress(false))
  }, [registerFido, setInProgress])

  return (
    <DetailView
      title={intl.formatMessage({ id: 'profile.detail.fido2.title' })}
      description={intl.formatMessage({
        id: 'profile.detail.fido2.description',
      })}
      backLink={backLink}
    >
      <Box sx={{ display: 'flex' }}>
        <Button size="large" onClick={handleClick} isLoading={inProgress}>
          {intl.formatMessage({ id: 'common.button.set-fido2' })}
        </Button>
      </Box>
    </DetailView>
  )
}

export default Fido2View
