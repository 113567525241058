import { featureFlags } from './FeatureFlagsDefaultData'

import { getPublicSettings } from 'common/selectors/settings'
import { getPublicSettings as getPublicSettingsAction } from 'common/actions/settings'
import { observeStore } from 'common/utils/observeStore'
import { constants } from 'common/components/Constants'

import isString from 'lodash/isString'
import get from 'lodash/get'
import store from '../../store'
import trim from 'lodash/trim'

class FeatureFlagsService {
  constructor() {
    if (!FeatureFlagsService.instance) {
      this.featureFlags = {}
      this.initilized = false
      FeatureFlagsService.instance = this
    }

    return FeatureFlagsService.instance
  }

  loadPublicSettings = settings => {
    // make it easier to debug FF related tickets
    // set node env REACT_APP_DEBUG_SETTINGS=true while in development mode
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_DEBUG_FLAGS === 'true'
    ) {
      this.featureFlags = {
        ...settings,
        ...this.featureFlags, // in debug mode local defaults get priority
      }
    } else {
      // in production
      this.featureFlags = {
        ...this.featureFlags,
        ...settings, // in prod, or non debug mode, backend response gets priority
      }
    }
  }

  /**
   * We want this method to be asynchronous
   * so we can easily replace it in future
   * with API Call
   */
  init = async () => {
    this.featureFlags = { ...featureFlags }
    await store.dispatch(getPublicSettingsAction())
    this.initilized = true

    if (this.unsubscribeHandler) {
      this.unsubscribeHandler()
      this.unsubscribeHandler = null
    }

    this.unsubscribeHandler = observeStore(
      store,
      getPublicSettings,
      this.loadPublicSettings,
    )
    Promise.resolve(this.unsubscribeHandler)
  }

  get = featureFlagName => {
    if (!this.initilized) {
      return
    }
    const state = store.getState()
    const override = get(state, 'configuration.config.override', {})

    const featureFlag = get(
      override,
      featureFlagName,
      this.featureFlags[featureFlagName],
    )

    if (isString(featureFlag)) {
      if (featureFlag.toLowerCase() === 'false') {
        return false
      }
      if (featureFlag.toLowerCase() === 'true') {
        return true
      }
    }

    return featureFlag
  }
}

const instance = new FeatureFlagsService()

// get convenience lookup with prefix already provided
export const getPrefixedFeatureFlagLookup = prefix => {
  return function prefixedFlagLookup(key) {
    return instance.get(`${prefix}.${key}`)
  }
}

export const hasUIFeatureFlag = getPrefixedFeatureFlagLookup(
  'uiFeatureFlags.idp',
)
export const getAppSetting = getPrefixedFeatureFlagLookup('system')

export const userFieldVisible = fieldName => {
  const fieldConfiguration = instance.get('process.userFields')
  const visibleFieldsArray = fieldConfiguration
    .split(',')
    .map(field => trim(field))

  return visibleFieldsArray.includes(fieldName)
}

export const getNameFormat = () => {
  if (userFieldVisible('firstName') && userFieldVisible('lastName')) {
    return constants.FIRST_LAST_NAME
  }

  if (userFieldVisible('displayName')) {
    return constants.FULL_NAME
  }

  //We return FIRST_LAST_NAME Name by default
  return constants.FIRST_LAST_NAME
}

export default instance
